import Footer from './Footer';

document.addEventListener('DOMContentLoaded', () => {
  const footerElement = document.getElementById('footer');

  if(footerElement) {
    const footer = new Footer(footerElement);
    footer.mount();
  }
});
