import 'mdn-polyfills/NodeList.prototype.forEach';
import vhCheck from 'vh-check';
import Toggler from '../toggler/Toggler';
import Navigation from '../../../shop/components/navigation/Navigation';

export default class Header {
    constructor(element) {
        this.element = element;
        this.navigationTogglers = [];
        this.sidebarTogglers = [];
        this.height = this.element.offsetHeight;
        this.hasResized = true;
        this.tmpWidth = 0;
        this.hasScrolled = true;
        this.tmpScroll = document.documentElement.scrollTop;
        this.lastDcrollDown = document.documentElement.scrollTop;
        this.navigation = null;
        this.navigationToggler = null;
        this.burger = null;
        this.direction = 'down';
        this.isPinned = false;
        this.isSticky = false;
        this.hasNavigationActive = false;

        this.handleNavigationHiding = this.handleNavigationHiding.bind(this);
        this.handleNavigationHide = this.handleNavigationHide.bind(this);
        this.handleNavigationShow = this.handleNavigationShow.bind(this);
        this.handleNavigationUpdate = this.handleNavigationUpdate.bind(this);
        this.handlePinned = this.handlePinned.bind(this);
        this.handleResize = this.handleResize.bind(this);

        if ('headerSticky' in this.element.dataset) {
            this.handleScroll = this.handleScroll.bind(this);
        }
    }

    handleNavigationHiding() {
        this.element.classList.add('has-navigation-hiding');
    }

    handleNavigationHide() {
        this.element.classList.remove('has-navigation-hiding');
        this.element.classList.remove('has-navigation-active');
        this.hasNavigationActive = false;

        this.navigation.togglers.forEach((toggler) => {
            toggler.hide(true);
        });

        this.navigationToggler.focus();
        this.navigationToggler = null;
        this.updateNavigation();
    }

    handleNavigationShow(e) {
        this.navigationToggler = e.detail.toggler.element;
        this.element.classList.add('has-navigation-active');
        this.hasNavigationActive = true;
        this.updateNavigation();
    }

    handleNavigationUpdate() {
        this.updateNavigation();
    }

    handlePinned() {
        this.element.removeEventListener('transitionend', this.handlePinned);
        this.element.classList.remove('is-pinned');
        this.element.classList.remove('is-leaving');
        this.isPinned = false;
    }

    handleScroll() {
        this.hasScrolled = true;
    }

    handleResize() {
        this.hasResized = true;
    }

    calculate(updateHeaderHeight = true) {
        const vh = vhCheck();
        document.documentElement.style.setProperty('--vh-offset', `${vh.offset}px`);

        if (updateHeaderHeight) {
            this.height = this.element.offsetHeight;
            document.documentElement.style.setProperty('--header-height', `${this.height}px`);
        }
    }

    updateNavigation() {
        let isNavigationHasTogglerExpanded = false;

        this.navigation.togglers.forEach((toggler) => {
            if (!toggler.target.contains(toggler.element)) {
                isNavigationHasTogglerExpanded = toggler.expanded === true ? true : isNavigationHasTogglerExpanded;
            }
        });

        this.calculate();
    }

    updateResize() {
        if (this.tmpWidth !== this.element.offsetWidth) {
            this.height = this.element.offsetHeight;
            this.navigationTogglers.forEach((toggler) => {
                const isTogglable = window.getComputedStyle(this.burger).getPropertyValue('display') !== 'none';

                if (toggler.mounted !== isTogglable) {
                    if (isTogglable) {
                        toggler.mount();
                    } else {
                        toggler.unmount();
                        this.element.classList.remove('has-navigation-active');
                        this.hasNavigationActive = false;
                    }
                }
            });

            this.sidebarTogglers.forEach((toggler) => {
                const isTogglable = window.getComputedStyle(this.burger).getPropertyValue('display') !== 'none';

                if (toggler.mounted !== isTogglable) {
                    if (isTogglable) {
                        toggler.mount();
                    } else {
                        toggler.unmount();
                    }
                }
            });

            this.tmpWidth = this.element.offsetWidth;
            this.calculate();
        }
    }

    updateScroll() {
        if (!this.hasNavigationActive) {
            if (document.documentElement.scrollTop > this.height) {
                this.element.classList.add('is-sticky');
                this.isSticky = true;
            } else {
                this.element.classList.remove('is-sticky');
                this.element.classList.remove('is-pinned');
                this.isSticky = false;
                this.isPinned = false;
                this.calculate();
            }

            if (this.isSticky) {
                if (!this.isPinned && document.documentElement.scrollTop > this.height + 20) {
                    this.element.classList.add('is-pinned');
                    this.isPinned = true;
                } else if (this.isPinned && document.documentElement.scrollTop <= this.height + 20) {
                    this.element.classList.remove('is-pinned');
                    this.isPinned = false;
                }
            }
        } else {
            this.calculate(false);
        }

        this.tmpScroll = document.documentElement.scrollTop;
    }

    mount() {
        const nav = this.element.querySelector('#navigation');
        if (nav) {
            this.navigation = new Navigation(nav);
            this.navigation.element.addEventListener('navigation:update', this.handleNavigationUpdate);
            this.navigation.mount();
        }

        this.burger = this.element.querySelector('#header-burger');

        this.element.querySelectorAll('[data-header-toggler="navigation"]').forEach((element) => {
            const target = document.getElementById(element.dataset.headerToggler);
            const toggler = new Toggler(element, target, true);
            this.navigationTogglers.push(toggler);
            element.addEventListener('toggler:hiding', this.handleNavigationHiding);
            element.addEventListener('toggler:hide', this.handleNavigationHide);
            element.addEventListener('toggler:show', this.handleNavigationShow);

            if (
                window.getComputedStyle(this.burger).getPropertyValue('display') !== 'none'
            ) {
                toggler.mount();
            }
        });

        this.element.querySelectorAll('[data-header-toggler="sidebar"]').forEach((element) => {
            const target = document.getElementById(element.dataset.headerToggler);
            const toggler = new Toggler(element, target);
            this.sidebarTogglers.push(toggler);

            if (
                window.getComputedStyle(this.burger).getPropertyValue('display') !== 'none'
            ) {
                toggler.mount();
            }
        });

        window.addEventListener('resize', this.handleResize);

        if ('headerSticky' in this.element.dataset) {
            window.addEventListener('scroll', this.handleScroll);
        }

        const loop = () => {
            if (this.hasScrolled) {
                this.updateScroll();
                this.hasScrolled = false;
            }
            if (this.hasResized) {
                this.updateResize();
                this.hasResized = false;
            }

            requestAnimationFrame(loop);
        };

        loop();

        this.element.classList.add('is-mounted');
        document.documentElement.classList.add('header-mounted');
        this.calculate();
    }

    unmount() {
        window.removeEventListener('resize', this.handleResize);

        if ('headerSticky' in this.element.dataset) {
            window.removeEventListener('scroll', this.handleScroll);
        }

        if (this.navigationTogglers) {
            this.navigationTogglers.forEach((toggler) => {
                toggler.element.removeEventListener('toggler:hiding', this.handleNavigationHiding);
                toggler.element.removeEventListener('toggler:hide', this.handleNavigationHide);
                toggler.element.removeEventListener('toggler:show', this.handleNavigationShow);
                toggler.unmount();
            });
            this.navigationTogglers = [];
        }

        if (this.navigation) {
            this.navigation.element.removeEventListener('navigation:update', this.handleNavigationUpdate);
            this.navigation.unmount();
            this.navigation = null;
        }

        this.element.classList.remove('is-mounted');
        document.documentElement.classList.remove('header-mounted');
    }
}
