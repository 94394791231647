import FormConditional from "./FormConditional";
import FormCollector from "./FormCollector";
import FormPasswordToggler from "./FormPasswordToggler";
import FormMaxlength from "./FormMaxlength";

export default class Form {
  constructor (element) {
    this.element = element;
    this.formPasswordTogglers = [];
    this.formConditionnals = [];
    this.formCollectors = [];
    this.formMaxlengths = [];

    this.handleFormCollectorAdd = this.handleFormCollectorAdd.bind(this);
  }

  handleFormCollectorAdd (e) {
    this.mountFormPasswordTogglers(e.detail.element);
    this.mountFormConditionnals(e.detail.element);
    this.mountFormCollectors(e.detail.element);
    this.mountFormMaxlengths(e.detail.element);
  }

  mountFormPasswordTogglers (node) {
    const formPasswordTogglers = node.querySelectorAll('[data-form-password-toggler]');
    if(formPasswordTogglers.length) {
      formPasswordTogglers.forEach((element) => {
        const formPasswordToggler = new FormPasswordToggler(element);
        formPasswordToggler.mount();
        this.formPasswordTogglers.push(formPasswordToggler);
      });
    }
  }

  mountFormConditionnals (node) {
    const formConditionnals = node.querySelectorAll('[data-form-conditional]');
    if(formConditionnals.length) {
      formConditionnals.forEach((element) => {
        const formConditionnal = new FormConditional(element);
        formConditionnal.mount();
        this.formConditionnals.push(formConditionnal);
      });
    }
  }

  mountFormCollectors (node) {
    const formCollectors = node.querySelectorAll('[data-form-collector]');
    if(formCollectors.length) {
      formCollectors.forEach((element) => {
        const formCollector = new FormCollector(element);
        formCollector.mount();

        formCollector.element.addEventListener('FormCollector:added', this.handleFormCollectorAdd);
        this.formCollectors.push(formCollector);
      });
    }
  }

  mountFormMaxlengths (node) {
    const formMaxlengths = node.querySelectorAll('input[maxlength], textarea[maxlength]');
    if(formMaxlengths.length) {
      formMaxlengths.forEach((element) => {
        const formMaxlength = new FormMaxlength(element);
        formMaxlength.mount();
        this.formMaxlengths.push(formMaxlength);
      });
    }
  }

  mount () {
    this.mountFormPasswordTogglers(this.element);
    this.mountFormConditionnals(this.element);
    this.mountFormCollectors(this.element);
    this.mountFormMaxlengths(this.element);

    if ('formAutosubmit' in this.element.dataset) {
      setTimeout(() => {
        this.element.submit();
      }, "6000");
    }
  }

  unmount () {
    this.formPasswordTogglers.forEach((formPasswordToggler) => {
      formPasswordToggler.unmount();
    });
    delete this.formPasswordTogglers;

    this.formConditionnals.forEach((formConditionnal) => {
      formConditionnal.unmount();
    });
    delete this.formConditionnals;

    this.formCollectors.forEach((formCollector) => {
      formCollector.element.removeEventListener('FormCollector:added', this.handleFormCollectorAdd);
      formCollector.unmount();
    });
    delete this.formCollectors;

    this.formMaxlengths.forEach((formMaxlength) => {
      formMaxlength.unmount();
    });
    delete this.formMaxlengths;
  }
}
