export default class FormPasswordToggler {
  constructor (element) {
    this.element = element;
    this.label = this.element.querySelector('[data-form-password-label]');
    this.target = document.getElementById(this.element.dataset.formPasswordToggler);
    this.translations = {
      hide: "Hide",
      show: "Show",
      password_shown: "Password shown",
      password_hidden: "Password hidden",
    };

    if(this.element.dataset.formPasswordTranslations) {
      this.translations = {
        ...this.translations,
        ...JSON.parse(this.element.dataset.formPasswordTranslations)
      }
    }

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick (e) {
    this.toggle();
  }

  hide () {
    this.element.setAttribute('aria-pressed', "false");
    this.target.setAttribute('type', "password");
    this.state = false;

    this.label.innerHTML = this.translations.show;
    this.live.innerHTML = this.translations.password_hidden;
  }

  show () {
    this.element.setAttribute('aria-pressed', "true");
    this.target.setAttribute('type', "text");
    this.state = true;

    this.label.innerHTML = this.translations.hide;
    this.live.innerHTML = this.translations.password_shown;
  }

  toggle () {
    this.state ? this.hide() : this.show();
  }

  mount () {
    if(this.target) {
      this.element.setAttribute('role', "switch");
      this.element.setAttribute('aria-pressed', "false");
      this.element.addEventListener('click', this.handleClick);
      this.state = false;

      const live = document.createElement('p');
      live.setAttribute('aria-live', "polite");
      live.classList.add('u-hidden-visually');

      this.live = this.element.parentNode.appendChild(live);
    }
  }

  unmount () {
    if(this.target) {
      this.element.removeAttribute('role');
      this.element.removeAttribute('aria-pressed');
      delete this.state;

      this.live.remove();
      delete this.live;

      this.element.removeEventListener('click', this.handleClick);
    }
  }
}
