/**
 * Name: Toaster
 * Description: Write_description_here
 */

export default class Toaster {
  constructor (message, type = "success", duration = 5000) {
    this.message = message;
    this.duration = duration;
    this.type = type;
    this.toaster = null;
    this.timeout = null;

    this.handleAnimationEnd = this.handleAnimationEnd.bind(this);
    this.handleTransitionEnd = this.handleTransitionEnd.bind(this);
  }

  handleAnimationEnd (e) {
    this.toaster.removeEventListener('animationend', this.handleAnimationEnd);

    this.timeout = setTimeout(() => {
      this.hide();
      clearTimeout(this.timeout);
      this.timeout = null;
    }, this.duration);
  }

  handleTransitionEnd (e) {
    this.toaster.removeEventListener('transitionend', this.handleTransitionEnd);
    this.remove();
  }

  render () {
    const root = document.createElement('div');
    root.classList.add('c-toaster');
    root.setAttribute('hidden', "");
    root.setAttribute('aria-live', this.type === "error" ? "assertive" : "polite");

    if (this.type === "error") {
      root.classList.add('c-toaster--error');
    }

    root.innerHTML = `
      <span class="c-pastil c-pastil--fill c-toaster__pastil" style="--background: var(--color-${ this.type }-500)">
        <svg width="24" height="24" class="c-icon c-pastil__icon" aria-hidden="true"><use href="/assets/www/images/sprite.svg#${ this.type === "error" ? "x" : "checkmark" }"></use></svg>
      </span>
      <p class="c-toaster__text">${ this.message }</p>
    `;
    return root;
  }

  show () {
    this.toaster.addEventListener('animationend', this.handleAnimationEnd);
    this.toaster.removeAttribute('hidden');
  }

  hide () {
    this.toaster.addEventListener('transitionend', this.handleTransitionEnd);
    this.toaster.classList.add('is-hiding');
  }

  remove () {
    this.toaster.remove();
  }

  mount () {
    this.toaster = this.render();
    document.body.appendChild(this.toaster);
    this.show();
  }

  unmount () {
    this.toaster.removeEventListener('animationend', this.handleAnimationEnd);
    this.toaster.removeEventListener('transitionend', this.handleTransitionEnd);

    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }

    this.toaster.remove();
    this.toaster = null;
  }
}
