export default class CartUpdate {
  constructor (element) {
    this.element = element;

    this.handleFormChange = this.handleFormChange.bind(this);
  }

  handleFormChange (e) {
    e.preventDefault();

    const formData = new FormData(this.form);
    formData.append(this.button.name, this.button.value);

    this.form.classList.add('is-loading');
    this.feedback.innerHTML = '';

    window.fetch(this.form.action, {
        method: this.form.method,
        cache: 'default',
        body: formData,
        headers: { 'X-Requested-With': 'XMLHttpRequest' }
    })
        .then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            }

            return response.text();
        })
        .then((html) => {
          const parser = new DOMParser();
          const doc = parser.parseFromString(html, "text/html");
          const form = doc.getElementById(this.element.dataset.cartUpdate);

          this.element = form.querySelector('[data-cart-update]');
          this.element.setAttribute('hidden', '');

          // TODO @manu : Improve Feedbacks
          if(form) {
            this.form.innerHTML = form.innerHTML;
            this.form.focus();
            this.feedback.innerHTML = 'Le panier à été mis à jour.';
          } else {
            // this.triggerComplete('error', null);
            alert('Une erreur s\'est produite, veuillez réessayer plus tard');
          }
          this.form.classList.remove('is-loading');
        })
        .catch((e) => {
          console.log({e});
          alert('Une erreur s\'est produite, veuillez réessayer plus tard');
          this.form.classList.remove('is-loading');
        });
  }

  mount () {
    this.form = document.getElementById(this.element.dataset.cartUpdate);
    this.button = this.element.getAttribute('type') == 'submit' ? this.element : document.querySelector('[type="submit"]');
    this.feedback = document.createElement('div');
    this.feedback.setAttribute('aria-live', 'polite');
    this.feedback.classList.add('u-hidden-visually');
    this.form.parentNode.insertBefore(this.feedback, this.form);

    this.element.setAttribute('hidden', '');

    this.form.addEventListener('change', this.handleFormChange);
  }

  unmount () {
    this.form.removeEventListener('change', this.handleFormChange);

    this.feedback.remove();

    delete this.form;
    delete this.button;
    delete this.feedback;

    this.element.removeAttribute('hidden');
  }
}
