import 'mdn-polyfills/NodeList.prototype.forEach';

/**
 * Tablist Element based on WAI-ARIA Practices
 * https://www.w3.org/TR/wai-aria-practices/examples/tabs/tabs-1/tabs.html
 */

export default class Tablist {
  constructor (element) {
    this.element = element;
    this.tabs = element.querySelectorAll('[data-tablist-target]');
    this.current = null;

    this.handleTabClick = this.handleTabClick.bind(this);
    this.handleTabKeyup = this.handleTabKeyup.bind(this);
    this.handleTabKeydown = this.handleTabKeydown.bind(this);
  }

  /**
   * @param {HTMLElement} tab
   */
  deactivate (tab) {
    const id = tab.getAttribute('aria-controls');
    const tabpanel = document.getElementById(id);
    tab.setAttribute('aria-selected', 'false');
    tab.setAttribute('tabindex', '-1');
    tabpanel.setAttribute('hidden', 'hidden');
    this.current = null;
  }

  /**
   * @param {HTMLElement} tab
   */
  activate (tab) {
    const id = tab.getAttribute('aria-controls');
    const tabpanel = document.getElementById(id);
    tab.setAttribute('aria-selected', 'true');
    tab.setAttribute('tabindex', '0');
    tabpanel.removeAttribute('hidden');
    this.current = tab;
  }

  handleTabClick (e) {
    e.preventDefault();
    this.deactivate(this.current);
    this.activate(e.currentTarget);
  }

  handleTabKeyup (e) {
    let index = null;

    if (e.key === 'ArrowRight' || e.key === 'Right') {
      e.preventDefault();
      index = (e.currentTarget === this.tabs[this.tabs.length - 1]) ? 0 : Array.prototype.indexOf.call(this.tabs, e.currentTarget) + 1;
    } else if (e.key === 'ArrowLeft' || e.key === 'Left') {
      e.preventDefault();
      index = (e.currentTarget === this.tabs[0]) ? this.tabs.length - 1 : Array.prototype.indexOf.call(this.tabs, e.currentTarget) - 1;
    }

    if (index !== null) {
      this.deactivate(e.currentTarget);
      this.activate(this.tabs[index]);
      this.current.focus();
    }
  }

  handleTabKeydown (e) {
    let index = null;

    if (e.key === 'Home') {
      index = 0;
    } else if (e.key === 'End') {
      index = this.tabs.length - 1;
    }

    if (index !== null) {
      e.preventDefault();
      this.deactivate(e.currentTarget);
      this.activate(this.tabs[index]);
      this.current.focus();
    }
  }

  mount () {
    this.current = this.tabs['0'];
    this.element.setAttribute('role', 'tablist');

    this.tabs.forEach((tab) => {
      const id = tab.getAttribute('data-tablist-target');
      const tabpanel = document.getElementById(id);
      const title = tabpanel.querySelector('[data-tablist-title]');

      if (tab.getAttribute('aria-selected') === 'true') {
        this.current = tab;
      }

      tab.setAttribute('id', `tab-${id}`);
      tab.setAttribute('role', 'tab');
      tab.setAttribute('aria-selected', 'false');
      tab.setAttribute('tabindex', '-1');
      tab.setAttribute('aria-controls', id);
      tabpanel.setAttribute('role', 'tabpanel');
      tabpanel.setAttribute('aria-labelledby', `tab-${id}`);
      tabpanel.setAttribute('hidden', 'hidden');
      tabpanel.setAttribute('tabindex', '0');

      if (title) {
        title.setAttribute('hidden', '');
      }

      tab.addEventListener('click', this.handleTabClick);
      tab.addEventListener('keyup', this.handleTabKeyup);
      tab.addEventListener('keydown', this.handleTabKeydown);
    });

    this.activate(this.current);
    this.element.classList.add('is-mounted');
  }

  unmount () {
    this.element.removeAttribute('role');

    this.tabs.forEach((tab) => {
      const id = tab.getAttribute('data-tablist-target');
      const tabpanel = document.getElementById(id);
      const title = tabpanel.querySelector('[data-tablist-title]');

      tab.removeAttribute('id');
      tab.removeAttribute('role');
      tab.removeAttribute('aria-selected');
      tab.removeAttribute('tabindex');
      tab.removeAttribute('aria-controls');
      tabpanel.removeAttribute('role');
      tabpanel.removeAttribute('aria-labelledby');
      tabpanel.removeAttribute('hidden');
      tabpanel.removeAttribute('tabindex');

      if (title) {
        title.removeAttribute('hidden');
      }

      tab.removeEventListener('click', this.handleTabClick);
      tab.removeEventListener('keyup', this.handleTabKeyup);
      tab.removeEventListener('keydown', this.handleTabKeydown);
    });

    this.current = null;
    this.element.classList.remove('is-mounted');
  }
}
