import 'mdn-polyfills/NodeList.prototype.forEach';
import Toggler from '../../../common/components/toggler/Toggler';

export default class Navigation {
  constructor (element) {
    this.element = element;
    this.togglers = [];
    this.toggler = null;
    this.hasMenu = false;
    this.tmp = this.element.offsetWidth;

    this.handleTargetChange = this.handleTargetChange.bind(this);
    this.handleTogglerHide = this.handleTogglerHide.bind(this);
    this.handleTogglerShow = this.handleTogglerShow.bind(this);
  }

  handleTargetChange (e) {
    this.updateBackdrop();
  }

  handleTogglerHide (e) {
    if(this.toggler && e.detail.toggler.lastEventType !== 'keyup') {
      this.toggler.focus();
    }
    if (this.toggler === e.detail.toggler.element) {
      this.toggler = null;
    }
    this.updateBackdrop();
    this.element.dispatchEvent(new CustomEvent('navigation:update'));
  }

  handleTogglerShow (e) {
    this.togglers.forEach((toggler) => {
      if (toggler.target !== e.detail.toggler.target && toggler.expanded) {
        toggler.hide();
      }
    });
    this.toggler = e.detail.toggler.element;
    this.updateBackdrop();
    this.element.dispatchEvent(new CustomEvent('navigation:update'));
  }

  updateBackdrop () {
    let hasTogglerExpanded = false;

    this.togglers.forEach((toggler) => {
      toggler.expanded ? hasTogglerExpanded = true : hasTogglerExpanded;
    })

    if(hasTogglerExpanded) {
      this.hasMenu = true;
      this.element.classList.add('has-menu');
    } else {
      this.hasMenu = false;
      this.element.classList.remove('has-menu');
    }
  }

  mount () {
    this.element.querySelectorAll('[data-navigation-toggler]').forEach((element) => {
      const target = document.getElementById(element.dataset.navigationToggler);

      if(target) {
        const toggler = new Toggler(element, target, true);
        this.togglers.push(toggler);

        element.addEventListener('toggler:hide', this.handleTogglerHide);
        element.addEventListener('toggler:show', this.handleTogglerShow);
        element.addEventListener('toggler:change', this.handleTargetChange);

        toggler.mount();
      }
    });
  }

  unmount () {
    this.hasMenu = false;
    this.element.classList.remove('has-menu');

    this.togglers.forEach((toggler) => {
      toggler.element.removeEventListener('toggler:hide', this.handleTogglerHide);
      toggler.element.removeEventListener('toggler:show', this.handleTogglerShow);
      toggler.element.removeEventListener('toggler:change', this.handleTargetChange);
      toggler.unmount();
    });

    this.togglers = [];
  }
}
