import CartAdd from './CartAdd';
import CartCount from './CartCount';
import CartUpdate from './CartUpdate';

export default class CartManager {
  constructor (element) {
    this.element = element;

    this.handleCartUpdate = this.handleCartUpdate.bind(this);
  }

  handleCartUpdate (e) {
    if(e.detail.count !== null && this.cartCounts.length > 0) {
      this.cartCounts.forEach((cartCount) => {
        cartCount.updateCount(e.detail.count);
      });
    } else {
      // TODO @manu : Improve Feedbacks
      console.log({e});
      alert('Une erreur s\'est produite, veuillez réessayer plus tard');
    }
  }

  mount () {
    // Add to cart elements
    const cartAddElements = document.querySelectorAll('[data-cart-add]');
    this.cartAdds = [];

    cartAddElements.forEach((cartAddElement) => {
      const cartAdd = new CartAdd(cartAddElement);
      this.cartAdds.push(cartAdd);
      cartAdd.element.addEventListener('cartAdd:complete', this.handleCartUpdate);

      cartAdd.mount();
    });

    // Counting cart items elements
    const cartCountElements = document.querySelectorAll('[data-cart-count]');
    this.cartCounts = [];

    cartCountElements.forEach((cartCountElement) => {
      const cartCount = new CartCount(cartCountElement);
      this.cartCounts.push(cartCount);

      cartCount.mount();
    });

    // Counting update
    const cartUpdateElements = document.querySelectorAll('[data-cart-update]');
    this.cartUpdates = [];

    cartUpdateElements.forEach((cartUpdateElement) => {
      const cartUpdate = new CartUpdate(cartUpdateElement);
      this.cartUpdates.push(cartUpdate);

      cartUpdate.mount();
    });
  }

  unmount () {
    this.cartAdds.forEach((cartAdd) => {
      cartAdd.element.removeEventListener('cartAdd:complete', this.handleCartUpdate);
      cartAdd.unmount();
    });
    delete this.cartAdds;

    this.cartCounts.forEach((cartCount) => {
      cartCount.unmount();
    });
    delete this.cartCounts;

    this.cartUpdates.forEach((cartUpdate) => {
      cartUpdate.unmount();
    });
    delete this.cartUpdates;
  }
}
