import Toggler from '../../../common/components/toggler/Toggler';

export default class PricingCard {
  constructor (element) {
    this.element = element;
    this.toggler = null;
    this.tmpWidth = 0;

    this.handleResize = this.handleResize.bind(this);
  }

  handleResize () {
    this.hasResized = true;
  }

  updateResize () {
    if (this.tmpWidth !== window.innerWidth) {
      const isTogglable = window.getComputedStyle(this.toggler.element).getPropertyValue('display') !== 'none';

      if (this.toggler.mounted !== isTogglable) {
        if (isTogglable) {
          this.toggler.mount();
        } else {
          this.toggler.unmount();
        }
      }

      this.tmpWidth = window.innerWidth;
    }
  }

  mount () {
    const togglerElement = this.element.querySelector('[data-pricing-card-toggler]');

    if(togglerElement) {
      const togglerTarget = document.getElementById(togglerElement.dataset.pricingCardToggler);

      if(togglerTarget) {
        this.toggler = new Toggler(togglerElement, togglerTarget);

        if (
          window.getComputedStyle(this.toggler.element).getPropertyValue('display') !== 'none'
        ) {
          this.toggler.mount();
        }

        window.addEventListener('resize', this.handleResize);

        const loop = () => {
          if (this.hasResized) {
            this.updateResize();
            this.hasResized = false;
          }

          requestAnimationFrame(loop);
        }

        loop();
      }
    }

    this.element.classList.add('is-mounted');
  }

  unmount () {
    this.toggler = null;
    this.element.classList.remove('is-mounted');
  }
}
