import 'mdn-polyfills/NodeList.prototype.forEach';
import { debounce } from '../../utilities/utilities';
import Toggler from '../toggler/Toggler';

export default class Footer {

  constructor (element) {
    this.element = element;
    this.togglers = [];
    this.tmp = this.element.offsetWidth;

    this.handleDebouncedResize = debounce(this.handleDebouncedResize.bind(this), 100);
  }

  handleDebouncedResize () {
    if (this.tmp !== this.element.offsetWidth) {

      this.togglers.forEach((toggler) => {
        const isTogglable = window.getComputedStyle(toggler.element).getPropertyValue('position') === 'relative';

        if (toggler.mounted !== isTogglable) {
          if (isTogglable) {
            toggler.mount();
          } else {
            toggler.unmount();
          }
        }
      });

      this.tmp = this.element.offsetWidth;
    }
  }

  mount () {
    this.element.querySelectorAll('[data-footer-toggler]').forEach((element) => {
      const target = document.getElementById(element.dataset.footerToggler);
      const toggler = new Toggler(element, target);
      this.togglers.push(toggler);

      if (window.getComputedStyle(element).getPropertyValue('position') === 'relative') {
        toggler.mount();
      }
    });

    window.addEventListener('resize', this.handleDebouncedResize);
    this.handleDebouncedResize();
  }

  unmount () {
    window.removeEventListener('resize', this.handleDebouncedResize);
    this.togglers.forEach((toggler) => {
      toggler.unmount();
    });
  }
}
