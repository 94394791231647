/**
 * Name: FormMaxlength
 * Description: Write_description_here
 */

export default class FormMaxlength {
  constructor (element) {
    this.element = element;
    this.maxlength = this.element.maxLength;
    this.help = document.getElementById(`${ this.element.id }_help`);
    this.helpInitalText = this.help?.innerHTML;

    this.handleKeyUp = this.handleKeyUp.bind(this);
  }

  handleKeyUp () {
    this.help.innerHTML = `${ this.helpInitalText } - ${ this.counterText() }`;
  }

  counterText () {
    return `${ this.element.value.length } sur ${ this.maxlength }`;
  }

  mount () {
    if (this.help) {
      this.help.innerHTML = `${ this.helpInitalText } - ${ this.counterText() }`;
      this.element.addEventListener('keyup', this.handleKeyUp);
    }
  }

  unmount () {
    if (this.help) {
      this.element.removeEventListener('keyup', this.handleKeyUp);
      this.help.innerHTML = this.helpInitalText;
    }
  }
}
