import 'mdn-polyfills/NodeList.prototype.forEach';

export default class FormConditional {
  constructor(element) {
    this.element = element;
    this.options = JSON.parse(this.element.dataset.formConditional);
    this.trigger = document.getElementById(this.options.trigger);
    this.requiredFields = this.element.querySelectorAll('[required]');

    if (this.options.value === "true" || this.options.value === "false") {
      this.options.value = this.options.value === "true";
    }

    this.handleTriggerChange = this.handleTriggerChange.bind(this);
  }

  show() {
    this.element.removeAttribute('hidden');

    this.requiredFields.forEach((element) => {
      element.setAttribute('required', 'required');
    });
  }

  hide() {
    this.element.setAttribute('hidden', '');

    this.requiredFields.forEach((element) => {
      element.removeAttribute('required');
    });
  }

  handleTriggerChange(e) {
    let currentInput = null;

    if (e?.target) {
      currentInput = e.target;
    } else {
      const firstInput = this.trigger.querySelector(`[name="${this.options.name}"]`);
      currentInput = firstInput.type === "radio" ? this.trigger.querySelector(`[name="${this.options.name}"]:checked`) : firstInput;
    }

    let currentValue = null;

    if (currentInput) {
      if (currentInput.type === 'checkbox') {
        currentValue = currentInput.checked;
      } else {
        currentValue = currentInput.value;
      }
    }

    if (Array.isArray(this.options.value)) {
      if (currentValue) {
        if (this.options.value.includes(currentValue)) {
          this.show();
        } else {
          this.hide();
        }
      } else {
        this.hide();
      }
    } else {
      if (currentValue === this.options.value) {
        this.show();
      } else {
        this.hide();
      }
    }
  }

  mount() {
    this.trigger.addEventListener('change', this.handleTriggerChange);
    this.handleTriggerChange();
  }

  unmount() {
    this.trigger.removeEventListener('change', this.handleTriggerChange);
    this.show();
  }
}
