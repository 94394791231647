export default class CartCount {
  constructor(element) {
    this.element = element;
  }

  updateCount (value) {
    this.element.dataset.cartCount = value > 0 ? value : '';
  }

  mount () {
    this.count = this.element.dataset.cartCount;
  }

  unmount () {
    this.count = null;
  }
}
