import 'mdn-polyfills/NodeList.prototype.forEach';
import PricingCard from './PricingCard';

document.addEventListener('DOMContentLoaded', () => {
  const pricingCards = document.querySelectorAll('[data-pricing-card]');
  if (!pricingCards.length) {
    return;
  }
  pricingCards.forEach((element) => {
    const pricingCard = new PricingCard(element);
    pricingCard.mount();
  });
});
