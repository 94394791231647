import Toaster from "../toaster/Toaster";

export default class CartAdd {
  constructor (element) {
    this.element = element;

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick (e) {
    e.preventDefault();

    this.launchRequest(this.element.href, {
        method: 'GET',
        cache: 'default',
        headers: { 'X-Requested-With': 'XMLHttpRequest' }
    });
  }

  handleSubmit (e) {
    e.preventDefault();

    const formData = new FormData(this.element);
    formData.append(e.submitter.name, e.submitter.value);

    this.launchRequest(this.element.action, {
      method: this.element.method,
      cache: 'default',
      body: formData,
      headers: { 'X-Requested-With': 'XMLHttpRequest' }
    });
  }

  launchRequest (url, data) {
    window.fetch(url, data)
        .then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            }

            return response.json();
        })
        .then((json) => {
          if(json.status === "Success") {
            this.triggerComplete('success', json.data.count);
            const toaster = new Toaster("Le produit a bien été ajouté au panier.");
            toaster.mount();
          } else {
            const toaster = new Toaster("Le produit n'a pas été ajouté au panier, veuillez réessayer plus tard.", "error");
            toaster.mount();
          }
        })
        .catch((e) => {
          console.log({e});
          alert('Une erreur s\'est produite, veuillez réessayer plus tard');
        });
  }

  triggerComplete (type, count) {
    const event = new CustomEvent(`cartAdd:complete`, {
      bubbles: false,
      cancelable: false,
      detail: { type: type, count: count }
    });
    this.element.dispatchEvent(event);
  }

  mount () {
    if (this.element.tagName.toLowerCase() === 'form') {
      this.element.addEventListener('submit', this.handleSubmit);
    } else {
      this.element.addEventListener('click', this.handleClick);
    }
  }

  unmount () {
    if (this.element.tagName.toLowerCase() === 'form') {
      this.element.removeEventListener('submit', this.handleSubmit);
    } else {
      this.element.removeEventListener('click', this.handleClick);
    }
  }
}
