import 'mdn-polyfills/NodeList.prototype.forEach';

export default class FormCollector {
  constructor (element) {
    this.element = element;
    this.id = this.element.dataset.formCollector;
    this.container = this.element.querySelector('[data-form-collector-container]');
    this.template = document.getElementById(`template-${this.id}`);
    this.addButton = this.element.querySelector('[data-form-collector-add]');
    this.removeButtons = this.element.querySelectorAll('[data-form-collector-remove]');
    this.index = parseInt(this.container.dataset.formCollectorContainer, 10);

    this.handleAddItem = this.handleAddItem.bind(this);
    this.handleRemoveItem = this.handleRemoveItem.bind(this);
  }

  handleAddItem (e) {
      const item = document.createElement('div');
      let html = this.template.innerHTML;
      item.innerHTML = html.replace(/__name__/g, this.index);

      this.container.appendChild(item);
      this.index += 1;

      item.querySelector('[data-form-collector-remove]').addEventListener('click', this.handleRemoveItem);

      this.removeButtons = this.element.querySelectorAll('[data-form-collector-remove]');

      this.element.dispatchEvent(new CustomEvent('FormCollector:added', { detail: { element: item } }));
  }

  handleRemoveItem (e) {
    const item = e.currentTarget.closest('[data-form-collector-item]');
    e.currentTarget.removeEventListener('click', this.handleRemoveItem);
    item.remove();
  }

  mount () {
    this.addButton.addEventListener('click', this.handleAddItem);
    this.removeButtons.forEach((button) => {
      button.addEventListener('click', this.handleRemoveItem);
    });
  }

  unmount () {
    this.addButton.removeEventListener('click', this.handleAddItem);
    this.removeButtons.forEach((button) => {
      button.removeEventListener('click', this.handleRemoveItem);
    });
  }
}
